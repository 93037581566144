@import '~bootstrap/dist/css/bootstrap.min.css';

.main_carousel .carousel-item{
    transition: transform 1.5s ease-in-out !important;
}

.btn-primary{
    border-radius: 20px;
}

@media screen and (max-width: 992px){
    .presentation_section img{
        box-shadow: none;
    }
}